export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"6394a351f0019100228cbc7a",
				"6394a351f0019100228cbc7e"
			]
		},
		"6394a351f0019100228cbc7a": {
			"id": "6394a351f0019100228cbc7a",
			"name": "404",
			"pageUrl": "404"
		},
		"6394a351f0019100228cbc7e": {
			"id": "6394a351f0019100228cbc7e",
			"name": "index",
			"pageUrl": "index"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {},
		"seo": {
			"favicon_apple_152px": "https://uploads.quarkly.io/6394a351f0019100228cbc66/images/icon.png?v=2022-12-14T20:34:32.802Z",
			"favicon_32px": "https://uploads.quarkly.io/6394a351f0019100228cbc66/images/favicon.png?v=2022-12-14T20:34:08.231Z",
			"favicon_win10_270px": "https://uploads.quarkly.io/6394a351f0019100228cbc66/images/workappto-logo-300.png?v=2022-12-14T20:34:47.361Z",
			"background_win10": "#000848",
			"og:title": "Workappto | App per il Training Online Professionale",
			"og:description": "L'app per la tua attività di training online, senza i costi e la complessità dello sviluppo di un'app!",
			"og:image": "https://uploads.quarkly.io/6394a351f0019100228cbc66/images/icon.png?v=2022-12-14T20:34:32.802Z",
			"title": "Workappto | App per il Training Online Professionale",
			"description": "L'app per la tua attività di training online, senza i costi e la complessità dello sviluppo di un'app!",
			"robotsFileType": "autogenerated"
		}
	}
}